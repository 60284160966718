import React from 'react';

import SEO from '../components/seo';
import Panel from '../components/panel';
import LinkOut from '../components/linkOut';
import Layout from '../components/layout';
import TopPanel from '../components/topPanel';
import ButtonLink from '../components/buttonLink';

import HorizontalTitle from '../components/horizontalTitle';
import HorizontalButtonsContainer from '../components/horizontalButtonsContainer';
import VerticalButtonsContainer from '../components/verticalButtonsContainer';

import overview from '../images/overview.png';

import ucsdLogo from '../images/ucsdLogo.png';
import hpaLogo from '../images/hpaLogo.png';
import harvardLogo from '../images/harvardLogo.png';
import kthLogo from '../images/kthLogo.png';
import ulbLogo from '../images/ulbLogo.png';

import styles from './pageStyles/index.module.css';

const contrastBackgroundColor = 'rgba(65,182,255, 0.25)';
const contrastTextColor = 'black';

const IndexPage = () => (
  <Layout>
    <SEO title='HEK293 MuSIC' />
    <TopPanel>
      <HorizontalTitle title='HEK293 MuSIC' subtitle='A Multi-Scale Integrated Cell' />
      <HorizontalButtonsContainer>
        <VerticalButtonsContainer>
          <ButtonLink
            text='View MuSIC Map in NDEx'
            link='https://www.ndexbio.org/?#/network/7fc70ab6-9fb1-11ea-aaef-0ac135e8bacf?accesskey=68afa0480a4859906b5d221619ee95679da96059680557f65c3dd9f1842e4930'
            tooltip={true}
            tooltipPosition='top'
            tooltipText={
              <span>
                {'NDEx is an online commons for biological networks. '}
                <span
                  style={{
                    display: 'inline-block',
                  }}
                >
                  <a href='#ndex' style={{ color: 'rgb(0, 0, 0, 0.8)' }}>
                    Learn more
                  </a>
                </span>
                {'.'}
              </span>
            }
          />
          <ButtonLink
            text='GitHub Code Repository'
            link='https://github.com/idekerlab/MuSIC'
            tooltip={true}
            tooltipPosition='bottom'
            tooltipText='The github repository containing the MuSIC pipeline code'
          />
        </VerticalButtonsContainer>

        <VerticalButtonsContainer>
          <ButtonLink
            text='Paper'
            link='https://www.nature.com/articles/s41586-021-04115-9'
            tooltip={true}
            tooltipPosition='top'
            tooltipText='The paper describing MuSIC'
          />
          <ButtonLink
            text='Data'
            link='#data'
            tooltip={true}
            tooltipPosition='bottom'
            tooltipText='Details on acquiring the data used by MuSIC'
            newTab={false}
          />
        </VerticalButtonsContainer>
        <VerticalButtonsContainer>
          <ButtonLink
            text={ 'Building MuSIC: A Step-by-step Guide'
              // <>
              //   <span style={{ display: 'inline-block' }}>Building MuSIC:</span>
              //   <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              //     A Step-by-step Guide
              //   </span>
            //  </>
           }
            link='https://github.com/idekerlab/MuSIC/wiki/A-Step-By-Step-Guide-to-Building-a-MuSIC-Map'
            tooltip={true}
            tooltipPosition='top'
            tooltipText='A step by step guide to building a MuSIC map'
            secondary={true}
          />
          <ButtonLink
            text='Companion Jupyter Notebook'
            link='https://github.com/idekerlab/MuSIC/blob/master/Step-by-step%20guide%20to%20build%20MuSIC%20v1.ipynb'
            tooltip={true}
            tooltipPosition='bottom'
            tooltipText='A Jupyter notebook reproducing MuSIC v1'
            secondary={true}
          />
        </VerticalButtonsContainer>
      </HorizontalButtonsContainer>
    </TopPanel>
    <Panel>
      <h2>What is MuSIC?</h2>
      <div>
      <p>
        {
       `MuSIC is a hierarchical map of eukaryotic cell architecture created from
        integrating immunofluorescence images in the Human Protein Atlas with
        affinity purification experiments from the BioPlex resource.`
        }
      </p>
      </div>
      <p>
        {
          'Two central approaches for mapping cellular structure – protein fluorescent imaging and protein biophysical association – each generate extensive datasets but of distinct qualities and resolutions that are typically treated separately. The MuSIC map is designed to address this challenge, by integrating immunofluorescent images in the Human Protein Atlas with ongoing affinity purification experiments from the BioPlex resource. The result is a unified hierarchical map of eukaryotic cell architecture. In the MuSIC hierarchy, nodes represent systems and arrows indicate containment of the lower system by the upper. Node color indicates known (gold) or putative novel (purple) systems. The size of each circle is based on the number of proteins in the system. The relative height of each system in the layout is determined based on the predicted diameter of the system in MuSIC.'
        }
      </p>
      <div className={styles.logosContainer}>
        <img src={overview} style={{ width: '50vw', minWidth: '10em' }} />
      </div>
    </Panel>
    <Panel
      backgroundColor={contrastBackgroundColor}
      textColor={contrastTextColor}
    >
      <a id='ndex'>
        <h2>What is NDEx?</h2>
      </a>
      <p>
        {'The '}
        <LinkOut link='https://www.ndexbio.org/?#/network/7fc70ab6-9fb1-11ea-aaef-0ac135e8bacf?accesskey=68afa0480a4859906b5d221619ee95679da96059680557f65c3dd9f1842e4930'>
          MuSIC
        </LinkOut>
        {' map is available via NDEx, the Network Data Exchange ('}
        <LinkOut link='http://ndexbio.org'>www.ndexbio.org</LinkOut>
        {', Pratt et. al. 2017 '}
        <LinkOut link='https://dx.doi.org/10.1158/0008-5472.CAN-17-0606'>
          DOI: 10.1158/0008-5472.CAN-17-0606
        </LinkOut>
        {
          '), which is an online commons for biological networks where networks of many types and formats can be shared, accessed, and stored by users and applications. NDEx is tightly integrated with Cytoscape ('
        }
        <LinkOut link='https://cytoscape.org/'>https://cytoscape.org/</LinkOut>
        {
          '), and the MuSIC network can be downloaded to Cytoscape by clicking the “Open in Cytoscape” button at the bottom-right of the web page.'
        }
      </p>
    </Panel>
    <Panel>
      <a id='data'>
        <h2>Data Availability</h2>
      </a>
      <p>
        <ul>
          <li>
            All IF images used in this study are available at{' '}
            <LinkOut link='https://www.proteinatlas.org/'>
              https://www.proteinatlas.org/
            </LinkOut>
            .
          </li>
          <li>
            The{' '}
            <LinkOut link='https://www.ndexbio.org/viewer/networks/98ba6a19-586e-11e7-8f50-0ac135e8bacf'>
              BioPlex 2.0
            </LinkOut>{' '}
            input network and the{' '}
            <LinkOut link='http://www.ndexbio.org/#/network/62f6c8f5-a05b-11ea-aaef-0ac135e8bacf?accesskey=06f0739d6558f98b222ce8e53d35d9a6788b589cbf0d529b00e730aeba35c31d'>
              newly generated AP-MS data
            </LinkOut>{' '}
            in this study can be accessed via the Network Data Exchange (
            <LinkOut link='https://ndexbio.org/'>NDEx</LinkOut>).
          </li>
        </ul>
      </p>
    </Panel>
    <Panel
      backgroundColor={contrastBackgroundColor}
      textColor={contrastTextColor}
    >
      <h2>How to cite?</h2>
      <p>
        Please cite{' '}
        <LinkOut link='https://www.biorxiv.org/content/10.1101/2020.06.21.163709v1 '>
          <strong>
            Qin et al., “Mapping cell structure across scales by fusing protein
            images and interactions”
          </strong>
        </LinkOut>
        .
      </p>
    </Panel>
    <Panel>
      <h2>Collaborators</h2>
      <div className={styles.logosContainer}>
        <div className={styles.logoGroup}>
          <LinkOut link='https://ucsd.edu/'>
            <img
              src={ucsdLogo}
              alt='UC San Diego logo'
              className={styles.logo}
              style={{ maxHeight: '4.5em' }}
            />
          </LinkOut>
          <h3>
            <LinkOut link='https://ucsd.edu/'>UC San Diego</LinkOut>
          </h3>
          <h3>
            <LinkOut link='http://idekerlab.ucsd.edu/'>Ideker Lab</LinkOut>,{' '}
            <LinkOut link='https://yeolab.github.io/'>Yeo Lab</LinkOut>
          </h3>
        </div>
        <div className={styles.logoGroup}>
          <LinkOut link='https://www.ulb.be/en'>
            <img
              src={ulbLogo}
              alt='Université libre de Bruxelles logo'
              className={styles.logo}
              style={{ maxHeight: '6em' }}
            />
          </LinkOut>
          <h3>
            <LinkOut link='https://www.ulb.be/en'>ULB</LinkOut>
          </h3>
          <h3>
            <LinkOut link='https://www.lafontainelab.com/'>
              Lafontaine Lab
            </LinkOut>
          </h3>
        </div>
      </div>
      <div className={styles.logosContainer}>
        <div className={styles.logoGroup}>
          <LinkOut link='https://www.kth.se/en'>
            <img src={kthLogo} alt='KTH Logo' className={styles.logo} />
          </LinkOut>
          <LinkOut link='https://www.proteinatlas.org/'>
            <img
              src={hpaLogo}
              alt='The Human Protein Atlas logo'
              className={styles.logo}
            />
          </LinkOut>
          <h3>
            <LinkOut link='https://www.kth.se/en'>KTH</LinkOut>
          </h3>
          <h3>
            <LinkOut link='https://cellprofiling.org/'>Lundberg Lab</LinkOut>
          </h3>
        </div>
        <div className={styles.logoGroup}>
          <LinkOut link='https://hms.harvard.edu/'>
            <img
              src={harvardLogo}
              alt='Harvard School of Medicine logo'
              className={styles.logo}
            />
          </LinkOut>
          <h3>
            <LinkOut link='https://hms.harvard.edu/'>
              Harvard Medical School
            </LinkOut>
          </h3>
          <h3>
            <LinkOut link='https://gygi.med.harvard.edu/'>Gygi Lab</LinkOut>,{' '}
            <LinkOut link='https://harper.hms.harvard.edu/'>Harper Lab</LinkOut>
          </h3>
        </div>
      </div>
    </Panel>
  </Layout>
);

export default IndexPage;
