import React from 'react';
import PropTypes from 'prop-types';

import styles from './componentStyles/buttonLink.module.css';


const ButtonLink = (props) => {
  return (
    <div className={props.tooltip ? styles.tooltip : ''}>
      <a href={props.link}
         target={props.newTab ? '_blank' : null} 
         rel={props.newTab ? 'noopener noreferrer' : null}
         className={styles.noUnderline}>
        <div
          className={[
            styles.divClass,
            props.vertical ? styles.vertical : styles.horizontal,
          ].join(' ')}
        >
          {props.text}
        </div>
      </a>
      <span
        className={[
          styles.tooltipText,
          props.tooltipPosition === 'top' ? styles.top : styles.bottom,
        ].join(' ')}
      >
        {props.tooltipText}
      </span>
      <span
        className={[
          styles.tooltipConnector,
          props.tooltipPosition === 'top' ? styles.top : styles.bottom,
        ].join(' ')}
      />
    </div>
  );
};

ButtonLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  vertical: PropTypes.bool,
  newTab: PropTypes.bool,
  tooltip: PropTypes.bool,
  tooltipText: PropTypes.node,
  tooltipPosition: PropTypes.string,
  secondary: PropTypes.bool,
};

ButtonLink.defaultProps = {
  vertical: true,
  newTab: true,
  tooltip: false,
  tooltipPosition: styles.top,
  secondary: false,
};

export default ButtonLink;
